import React from "react"
import { navigate, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./NewsCard.scss"

const NewsCard = ({ image, media, month, year, slug }) => {
  const goToNews = () => {
    navigate(`/reportajes/${slug}`)
  }

  return (
    <div className="NewsCard">
      <div className="NewsCard__imgContainer row justify-content-center mb-2">
        <GatsbyImage
          image={getImage(image)}
          onClick={goToNews}
          className="NewsCard__imgContainer--img"
          alt=""
        />
      </div>
      <div className="NewsCard__infoCont row">
        <Link to={`reportajes/${slug}`} className="NewsCard__infoCont--text">
          <p><b>{media}</b> <br /> {month} {year}{" "}</p>
        </Link>
      </div>
    </div>
  )
}

export default NewsCard
