import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import NewsCard from "../components/NewsCard/NewsCard"
import Seo from "../components/seo"
import useViewport from "../Hooks/useViewport"
import useSeoQuery from "../queries/useSeoQuery"

const useNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulReportajes(sort: {fields: ano, order: ASC}) {
        edges {
          node {
            medio
            ano
            mes
            url
            portada {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `)

  return data.allContentfulReportajes.edges
}

const Reportajes = () => {
  const data = useNews()
  const seo = useSeoQuery()
  useViewport()

  return (
    <Layout>
      <Seo
        title="Reportajes"
        lang="es"
        titleSEO={seo.reportajesTitleSeo}
        description={seo.reportajesDescriptionSeo.reportajesDescriptionSeo}
      />
      <h1 className="seo-title">dobleLdesing en los medios</h1>

      <div className="container">
        <div className="row">
          {data?.map((news, i) => (
            <div
              className="col-lg-4 col-md-6 mb-5 px-5 pb-5 justify-content-center"
              key={i}
            >
              <NewsCard
                image={news.node.portada.gatsbyImageData}
                media={news.node.medio}
                month={news.node.mes}
                year={news.node?.ano || "2020"}
                slug={news.node.url}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Reportajes
